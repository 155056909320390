// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: false,
  defaultauth: 'aws',

  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },
  confirm: {
   email: '',
   password: ''
  },
  urlapi:{
   default:'https://x6gyojf060.execute-api.us-east-1.amazonaws.com/prod/',
   intervencionequipos:'https://cyqzq2zi4h.execute-api.us-east-1.amazonaws.com/pocbackend/',
   intervencionequiposfuga:'https://rkymk5y6g1.execute-api.us-east-1.amazonaws.com/pocbackend/',
   jerarquiaequipos:'https://jjcm8l4rei.execute-api.us-east-1.amazonaws.com/pocbackend/',
   juntasbridadas:'https://6338x24t1i.execute-api.us-east-1.amazonaws.com/pocbackend/',
   maquetaforge:'https://api.modelo3d.reliabytics.com/api/forge/oauth/token',
   topbar:"https://w40cwl5ok0.execute-api.us-east-1.amazonaws.com/pocbackend/",
   ciclovida:"https://rf24vlrjri.execute-api.us-east-1.amazonaws.com/",
   recomendacion:'https://wpnegsazlc.execute-api.us-east-1.amazonaws.com/',
   recomendacion_aviso:"https://qwuhk7pc6e.execute-api.us-east-1.amazonaws.com/pocbackend/",
   recomendacion_comp:"https://3nqefd0q0l.execute-api.us-east-1.amazonaws.com/pocbackend/",
   rondasoperacionales:'https://fyga5bk4lk.execute-api.us-east-1.amazonaws.com/',
   rondasoperacionales_tarea:'https://e217pygqad.execute-api.us-east-1.amazonaws.com/',
   dataloadersrbi:"https://owmjhxvqba.execute-api.us-east-1.amazonaws.com/dev/",
   panelrbi:"https://eupranim6e.execute-api.us-east-1.amazonaws.com/pocbackend/",
   usuario_roles:'https://ft7hbykis7.execute-api.us-east-1.amazonaws.com/pocbackend/',
   alarma:"https://rkvwv2k3qa.execute-api.us-east-1.amazonaws.com/tenant/",
   quicksight:''
  },
  identityPoolId:'us-east-1:6a40d056-837b-4e46-954c-a6bd0145144d',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
